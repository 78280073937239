/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CardAttributesDto } from './CardAttributesDto';
import {
    CardAttributesDtoFromJSON,
    CardAttributesDtoFromJSONTyped,
    CardAttributesDtoToJSON,
} from './CardAttributesDto';
import type { PayerDto } from './PayerDto';
import {
    PayerDtoFromJSON,
    PayerDtoFromJSONTyped,
    PayerDtoToJSON,
} from './PayerDto';

/**
 * 
 * @export
 * @interface PayDebtDto
 */
export interface PayDebtDto {
    /**
     * 
     * @type {number}
     * @memberof PayDebtDto
     */
    amount_cents: number;
    /**
     * 
     * @type {string}
     * @memberof PayDebtDto
     */
    debt_id: string;
    /**
     * 
     * @type {string}
     * @memberof PayDebtDto
     */
    celcoin_id: string;
    /**
     * 
     * @type {CardAttributesDto}
     * @memberof PayDebtDto
     */
    card_attributes: CardAttributesDto;
    /**
     * 
     * @type {number}
     * @memberof PayDebtDto
     */
    installments: number;
    /**
     * 
     * @type {PayerDto}
     * @memberof PayDebtDto
     */
    payer: PayerDto;
}

/**
 * Check if a given object implements the PayDebtDto interface.
 */
export function instanceOfPayDebtDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount_cents" in value;
    isInstance = isInstance && "debt_id" in value;
    isInstance = isInstance && "celcoin_id" in value;
    isInstance = isInstance && "card_attributes" in value;
    isInstance = isInstance && "installments" in value;
    isInstance = isInstance && "payer" in value;

    return isInstance;
}

export function PayDebtDtoFromJSON(json: any): PayDebtDto {
    return PayDebtDtoFromJSONTyped(json, false);
}

export function PayDebtDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayDebtDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'debt_id': json['debt_id'],
        'celcoin_id': json['celcoin_id'],
        'card_attributes': CardAttributesDtoFromJSON(json['card_attributes']),
        'installments': json['installments'],
        'payer': PayerDtoFromJSON(json['payer']),
    };
}

export function PayDebtDtoToJSON(value?: PayDebtDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'debt_id': value.debt_id,
        'celcoin_id': value.celcoin_id,
        'card_attributes': CardAttributesDtoToJSON(value.card_attributes),
        'installments': value.installments,
        'payer': PayerDtoToJSON(value.payer),
    };
}

