/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUnpaidBilletDto
 */
export interface GetUnpaidBilletDto {
    /**
     * 
     * @type {string}
     * @memberof GetUnpaidBilletDto
     */
    seller_id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetUnpaidBilletDto
     */
    reference_date: Date;
    /**
     * 
     * @type {number}
     * @memberof GetUnpaidBilletDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetUnpaidBilletDto
     */
    seller_cns: string;
    /**
     * 
     * @type {string}
     * @memberof GetUnpaidBilletDto
     */
    seller_name: string;
}

/**
 * Check if a given object implements the GetUnpaidBilletDto interface.
 */
export function instanceOfGetUnpaidBilletDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "seller_id" in value;
    isInstance = isInstance && "reference_date" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "seller_cns" in value;
    isInstance = isInstance && "seller_name" in value;

    return isInstance;
}

export function GetUnpaidBilletDtoFromJSON(json: any): GetUnpaidBilletDto {
    return GetUnpaidBilletDtoFromJSONTyped(json, false);
}

export function GetUnpaidBilletDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUnpaidBilletDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seller_id': json['seller_id'],
        'reference_date': (new Date(json['reference_date'])),
        'amount': json['amount'],
        'seller_cns': json['seller_cns'],
        'seller_name': json['seller_name'],
    };
}

export function GetUnpaidBilletDtoToJSON(value?: GetUnpaidBilletDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seller_id': value.seller_id,
        'reference_date': (value.reference_date.toISOString()),
        'amount': value.amount,
        'seller_cns': value.seller_cns,
        'seller_name': value.seller_name,
    };
}

