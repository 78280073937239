/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    complement: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city: string;
}

/**
 * Check if a given object implements the AddressDto interface.
 */
export function instanceOfAddressDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "zipcode" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "complement" in value;
    isInstance = isInstance && "district" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "city" in value;

    return isInstance;
}

export function AddressDtoFromJSON(json: any): AddressDto {
    return AddressDtoFromJSONTyped(json, false);
}

export function AddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zipcode': json['zipcode'],
        'street': json['street'],
        'number': json['number'],
        'complement': json['complement'],
        'district': json['district'],
        'state': json['state'],
        'city': json['city'],
    };
}

export function AddressDtoToJSON(value?: AddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zipcode': value.zipcode,
        'street': value.street,
        'number': value.number,
        'complement': value.complement,
        'district': value.district,
        'state': value.state,
        'city': value.city,
    };
}

