/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecallData
 */
export interface RecallData {
    /**
     * 
     * @type {string}
     * @memberof RecallData
     */
    register_date?: string;
    /**
     * 
     * @type {string}
     * @memberof RecallData
     */
    formatted_register_date?: string;
    /**
     * 
     * @type {string}
     * @memberof RecallData
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof RecallData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RecallData
     */
    chassis?: string;
    /**
     * 
     * @type {string}
     * @memberof RecallData
     */
    type?: string;
}

/**
 * Check if a given object implements the RecallData interface.
 */
export function instanceOfRecallData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function RecallDataFromJSON(json: any): RecallData {
    return RecallDataFromJSONTyped(json, false);
}

export function RecallDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecallData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'register_date': !exists(json, 'register_date') ? undefined : json['register_date'],
        'formatted_register_date': !exists(json, 'formatted_register_date') ? undefined : json['formatted_register_date'],
        'description': json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'chassis': !exists(json, 'chassis') ? undefined : json['chassis'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function RecallDataToJSON(value?: RecallData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'register_date': value.register_date,
        'formatted_register_date': value.formatted_register_date,
        'description': value.description,
        'name': value.name,
        'chassis': value.chassis,
        'type': value.type,
    };
}

