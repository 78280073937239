import React from 'react';
import { FormLabel, Input, InputProps, Stack, Textarea } from '@chakra-ui/react';
interface AtpvInputProps extends InputProps {
	py?: number | string;
	w?: string | string[];
	gap?: number | string;
	value?: any;
	children?: string;
	className?: string;
	borderTop?: string;
	minH?: string;
	borderRight?: string;
	isTextArea?: boolean;
	id: string;
}
const AtpvInput: React.FC<AtpvInputProps> = React.forwardRef(
	(
		{ w, minH, gap = 0, py = '12px', children, value, id, className, borderTop = '1px solid #434041', borderRight = 'none', isTextArea = false },
		ref
	) => {
		return (
			<Stack borderTop={borderTop} borderRight={borderRight} gap={gap} py={py} className={className} w={w}>
				<FormLabel m='0' fontSize='12px'>
					{children}
				</FormLabel>

				{!isTextArea ? (
					<Input id={id} m='0' variant='unstyled' value={value} readOnly />
				) : (
					<Textarea minH={minH} textAlign='justify' w='100%' resize='none' readOnly variant='unstyled' value={value} id={id} fontSize={['12', '12', '16']} />
				)}
			</Stack>
		);
	}
);
export default AtpvInput;
