/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressDto } from './AddressDto';
import {
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
} from './AddressDto';

/**
 * 
 * @export
 * @interface PayerDto
 */
export interface PayerDto {
    /**
     * 
     * @type {string}
     * @memberof PayerDto
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof PayerDto
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof PayerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof PayerDto
     */
    email: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof PayerDto
     */
    address: AddressDto;
}

/**
 * Check if a given object implements the PayerDto interface.
 */
export function instanceOfPayerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "document" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function PayerDtoFromJSON(json: any): PayerDto {
    return PayerDtoFromJSONTyped(json, false);
}

export function PayerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'document': json['document'],
        'email': json['email'],
        'address': AddressDtoFromJSON(json['address']),
    };
}

export function PayerDtoToJSON(value?: PayerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'document': value.document,
        'email': value.email,
        'address': AddressDtoToJSON(value.address),
    };
}

