/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetOwnerDto } from './GetOwnerDto';
import {
    GetOwnerDtoFromJSON,
    GetOwnerDtoFromJSONTyped,
    GetOwnerDtoToJSON,
} from './GetOwnerDto';
import type { GetVehicleInquiriesDto } from './GetVehicleInquiriesDto';
import {
    GetVehicleInquiriesDtoFromJSON,
    GetVehicleInquiriesDtoFromJSONTyped,
    GetVehicleInquiriesDtoToJSON,
} from './GetVehicleInquiriesDto';

/**
 * 
 * @export
 * @interface GetVehicleDto
 */
export interface GetVehicleDto {
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    licensePlate: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    chassis: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    stateRegistration: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    cityRegistration: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    renavam: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    stateJurisdiction?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    InvoicedIdentificationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    stateInvoice?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    manufactureYear: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    modelYear: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    brandModel: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    specie: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    fuel: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    mountingType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    potency: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    cylinderCapacity: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    seats: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    cmc?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    totalWeightGross?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    maximumTractionCapacity?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    gearbox?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    engineNumber?: string;
    /**
     * 
     * @type {GetOwnerDto}
     * @memberof GetVehicleDto
     */
    owner: GetOwnerDto;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    document_number?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    category?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetVehicleDto
     */
    issueDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleDto
     */
    hasAtpve?: boolean;
    /**
     * 
     * @type {GetVehicleInquiriesDto}
     * @memberof GetVehicleDto
     */
    inquiries?: GetVehicleInquiriesDto;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleDto
     */
    atpve_url?: string;
}

/**
 * Check if a given object implements the GetVehicleDto interface.
 */
export function instanceOfGetVehicleDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "licensePlate" in value;
    isInstance = isInstance && "chassis" in value;
    isInstance = isInstance && "stateRegistration" in value;
    isInstance = isInstance && "cityRegistration" in value;
    isInstance = isInstance && "renavam" in value;
    isInstance = isInstance && "manufactureYear" in value;
    isInstance = isInstance && "modelYear" in value;
    isInstance = isInstance && "brandModel" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "specie" in value;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "fuel" in value;
    isInstance = isInstance && "potency" in value;
    isInstance = isInstance && "cylinderCapacity" in value;
    isInstance = isInstance && "seats" in value;
    isInstance = isInstance && "owner" in value;

    return isInstance;
}

export function GetVehicleDtoFromJSON(json: any): GetVehicleDto {
    return GetVehicleDtoFromJSONTyped(json, false);
}

export function GetVehicleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVehicleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licensePlate': json['licensePlate'],
        'chassis': json['chassis'],
        'stateRegistration': json['stateRegistration'],
        'cityRegistration': json['cityRegistration'],
        'renavam': json['renavam'],
        'stateJurisdiction': !exists(json, 'stateJurisdiction') ? undefined : json['stateJurisdiction'],
        'InvoicedIdentificationNumber': !exists(json, 'InvoicedIdentificationNumber') ? undefined : json['InvoicedIdentificationNumber'],
        'stateInvoice': !exists(json, 'stateInvoice') ? undefined : json['stateInvoice'],
        'manufactureYear': json['manufactureYear'],
        'modelYear': json['modelYear'],
        'brandModel': json['brandModel'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'type': json['type'],
        'specie': json['specie'],
        'color': json['color'],
        'fuel': json['fuel'],
        'mountingType': !exists(json, 'mountingType') ? undefined : json['mountingType'],
        'potency': json['potency'],
        'cylinderCapacity': json['cylinderCapacity'],
        'seats': json['seats'],
        'cmc': !exists(json, 'cmc') ? undefined : json['cmc'],
        'totalWeightGross': !exists(json, 'totalWeightGross') ? undefined : json['totalWeightGross'],
        'maximumTractionCapacity': !exists(json, 'maximumTractionCapacity') ? undefined : json['maximumTractionCapacity'],
        'gearbox': !exists(json, 'gearbox') ? undefined : json['gearbox'],
        'engineNumber': !exists(json, 'engineNumber') ? undefined : json['engineNumber'],
        'owner': GetOwnerDtoFromJSON(json['owner']),
        'document_number': !exists(json, 'document_number') ? undefined : json['document_number'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'issueDate': !exists(json, 'issueDate') ? undefined : (new Date(json['issueDate'])),
        'hasAtpve': !exists(json, 'hasAtpve') ? undefined : json['hasAtpve'],
        'inquiries': !exists(json, 'inquiries') ? undefined : GetVehicleInquiriesDtoFromJSON(json['inquiries']),
        'atpve_url': !exists(json, 'atpve_url') ? undefined : json['atpve_url'],
    };
}

export function GetVehicleDtoToJSON(value?: GetVehicleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licensePlate': value.licensePlate,
        'chassis': value.chassis,
        'stateRegistration': value.stateRegistration,
        'cityRegistration': value.cityRegistration,
        'renavam': value.renavam,
        'stateJurisdiction': value.stateJurisdiction,
        'InvoicedIdentificationNumber': value.InvoicedIdentificationNumber,
        'stateInvoice': value.stateInvoice,
        'manufactureYear': value.manufactureYear,
        'modelYear': value.modelYear,
        'brandModel': value.brandModel,
        'group': value.group,
        'type': value.type,
        'specie': value.specie,
        'color': value.color,
        'fuel': value.fuel,
        'mountingType': value.mountingType,
        'potency': value.potency,
        'cylinderCapacity': value.cylinderCapacity,
        'seats': value.seats,
        'cmc': value.cmc,
        'totalWeightGross': value.totalWeightGross,
        'maximumTractionCapacity': value.maximumTractionCapacity,
        'gearbox': value.gearbox,
        'engineNumber': value.engineNumber,
        'owner': GetOwnerDtoToJSON(value.owner),
        'document_number': value.document_number,
        'category': value.category,
        'issueDate': value.issueDate === undefined ? undefined : (value.issueDate.toISOString()),
        'hasAtpve': value.hasAtpve,
        'inquiries': GetVehicleInquiriesDtoToJSON(value.inquiries),
        'atpve_url': value.atpve_url,
    };
}

