import React from 'react';
import { Box, Flex, Image, Spinner, Stack } from '@chakra-ui/react';

import { GetReportDto, GetVehicleDto, GetVehicleInquiriesDto, Recalls } from '../../../clients';

import { InquiriesPrint } from '../../../components/InquiriesPrint';
import Text from '../../../components/Text';
import Title from '../../../components/Title';

import { cpfCnpjMask } from '../../../services/cpfCnpj.service';

import AtpvInput from './AtpvInput';

interface AtpvDocumentProps {
	componentRef?: React.RefObject<HTMLDivElement>;
	licensePlateData?: GetVehicleDto | undefined;
	report?: GetReportDto | undefined;
	inquiries?: GetVehicleInquiriesDto | undefined;
	isAtpveSuccess?: boolean;
	atpveUrlLoading?: boolean;
	recalls: Recalls | undefined;
	recallsLoading: boolean;
}

const AtpvDocument: React.FC<AtpvDocumentProps> = (props: AtpvDocumentProps) => {
	const searchDate = props.report?.reprocessing_date
		? props.report?.reprocessing_date.toLocaleString('pt-BR')
		: props.report?.created_at.toLocaleString('pt-BR');

	const printHeader = {
		'@media print': {
			padding: '15px',
		},
	};

	return (
		<Stack flexDirection='column' mt='25px' ref={props.componentRef}>
			<Stack
				flexDirection={['column', 'row']}
				wrap={['wrap']}
				justifyContent='space-between'
				mt='25px'
				css={printHeader}
				minHeight='min-content'
				maxH={['20%', '20%', '100%']}
			>
				<Flex maxW='88%' height='min-content'>
					<Title fontSize={['lg', 'lg', '3xl']} fontWeight={350} marginBottom='40px' borderBottom='2px solid black'>
						ATPVE - Autorização para Transferência de propriedade de veículo
					</Title>
				</Flex>
				<Flex display={['none', 'none', 'none', 'none', 'none', 'flex']} alignItems='center' maxW='12%'>
					<Image src={require('../../../assets/images/seal.png')} />
				</Flex>
				<Flex
					height='min-content'
					css={{
						'@media screen': {
							display: 'flex',
						},
						'@media print': {
							display: 'none',
						},
					}}
				>
					{props.licensePlateData?.stateRegistration === 'MG' ? (
						props.atpveUrlLoading ? (
							<Flex>
								<Spinner />
								<Text ml='5' textAlign={['center', 'center', 'start']} fontWeight='bold'>
									Realizando download do ATPVe
								</Text>
							</Flex>
						) : (
							<>
								<Text textAlign={['center', 'center', 'start']} fontWeight='bold' color={props.isAtpveSuccess ? 'green' : 'red'}>
									{props.isAtpveSuccess ? (
										'Download da ATPVe realizado.'
									) : (
										<Text>
											Veículo não possui intenção de venda, conforme Detran MG.
											<br />
											Se os dados espelhados na tela da consulta estiverem de acordo com o documento do usuário, o cartório poderá
											prosseguir com o reconhecimento.
										</Text>
									)}
								</Text>
							</>
						)
					) : (
						<Text textAlign={['center', 'center', 'start']} fontWeight='bold' color={'gray'}>
							Documento ATPVe não disponível.
							<br /> Download disponível apenas para veículos de MG
							<br /> Se os dados espelhados na tela da consulta estiverem de acordo com o documento do usuário, o cartório poderá prosseguir
							com o reconhecimento.
						</Text>
					)}
				</Flex>
			</Stack>
			<Stack alignItems='center' w='100%'>
				<Stack flexDirection='column' mt={15} w={['95%', '95%', '100%']} borderTop='40px solid #434041' alignItems='stretch' gap={0}>
					<Stack w='100%' flexDirection={['column', 'row']} borderBottom='2px dotted #434041' gap={0} className='atpv-print-box'>
						<Stack w={['100%', '50%']} borderRight={['none', '2px dotted #434041']} className='atpv-print-border-right'>
							<Stack my='20px' alignItems='center'>
								<Text fontWeight='bold' textAlign='center' fontSize={['10px', '10px', '14px']} className='print-title'>
									AUTORIZAÇÃO PARA TRANSFERÊNCIA DE PROPRIEDADE DE VEÍCULO
								</Text>
							</Stack>
							<Stack w='60%' flexDirection='column'>
								<AtpvInput children='CÓDIGO RENAVAM' value={props.licensePlateData?.renavam} id='input__vehicle-renavam' />
								<AtpvInput children='PLACA' value={props.licensePlateData?.licensePlate} id='input__vehicle-license-plate' />
								<Stack borderTop='1px solid #434041' flexDirection='row' py='12px'>
									<AtpvInput
										children='ANO FABRICAÇÃO'
										value={props.licensePlateData?.manufactureYear}
										id='input__vehicle-manufacture-year'
										borderRight='1px solid #434041'
										borderTop='none'
										py={0}
									/>
									<AtpvInput
										children='ANO MODELO'
										value={props.licensePlateData?.modelYear}
										id='input__vehicle-model-year'
										borderTop='none'
										py={0}
									/>
								</Stack>
							</Stack>
							<Stack pr='20px' flexDirection='column'>
								<AtpvInput children='MARCA / MODELO / VERSÃO' value={props.licensePlateData?.brandModel} id='input__vehicle-brand-model' />
								{props.licensePlateData?.category && (
									<AtpvInput children='CAT.' value={props.licensePlateData?.category} id='input__vehicle-category' />
								)}
								<Stack borderTop='1px solid #434041' py='12px' flexDirection='row'>
									{props.licensePlateData?.color && (
										<AtpvInput
											children='COR PREDOMINANTE'
											value={props.licensePlateData?.color}
											id='input__vehicle-color'
											borderTop='none'
											borderRight='1px solid #434041'
											w='45%'
											py={0}
										/>
									)}
									<AtpvInput
										children='CHASSI'
										value={props.licensePlateData?.chassis}
										id='input__vehicle-chassis'
										borderTop='none'
										w={props.licensePlateData?.color ? '55%' : '100%'}
										py={0}
									/>
								</Stack>
								{!props.licensePlateData?.document_number && !props.licensePlateData?.issueDate ? null : (
									<Stack borderTop='1px solid #434041' py='12px' flexDirection='row'>
										{props.licensePlateData?.document_number && (
											<AtpvInput
												children='NÚMERO ATPVe'
												value={props.licensePlateData?.document_number}
												id='input__vehicle-document-number'
												borderTop='none'
												w='45%'
												borderRight='1px solid #434041'
												py={0}
											/>
										)}
										{props.licensePlateData?.issueDate && (
											<AtpvInput
												children='DATA EMISSÃO DO ATPVe'
												value={props.licensePlateData?.issueDate?.toLocaleString('pt-BR')}
												id='input__vehicle-issue-date'
												borderTop='none'
												w='55%'
												borderRight='1px solid #434041'
												py={0}
											/>
										)}
									</Stack>
								)}
							</Stack>
						</Stack>
						<Stack w={['100%', '50%']}>
							<Stack pl={[0, 5]} w='100%' className='atpv-print-margin-left'>
								<Stack alignContent='start' mt='12px'>
									<Text fontWeight='bold' fontSize={['10px', '10px', '14px']} className='atpv-print-title'>
										IDENTIFICAÇÃO DO VENDEDOR
									</Text>
								</Stack>
								<Stack pr='20px' flexDirection='column'>
									<AtpvInput children='NOME' value={props.licensePlateData?.owner?.name} id='input__seller-name' />
									<AtpvInput
										children='CPF / CNPJ'
										value={cpfCnpjMask(props.licensePlateData?.owner?.document as string)}
										id='input__seller-cpf-cnpj'
									/>
									<Stack borderTop='1px solid #434041' py='12px' flexDirection='row'>
										<AtpvInput
											children='MUNICÍPIO DE DOMICÍLIO OU RESIDÊNCIA'
											value={props.licensePlateData?.cityRegistration}
											id='input__seller-city'
											borderTop='none'
											w='85%'
											borderRight='1px solid #434041'
											py={0}
										/>
										<AtpvInput
											children='UF'
											value={props.licensePlateData?.stateRegistration}
											id='input__seller-state'
											borderTop='none'
											w='15%'
											py={0}
										/>
									</Stack>
								</Stack>
								{props.recallsLoading ? (
									<Box display='flex' flexDirection='row' alignItems='center' gap='10px'>
										<Spinner size='sm' />
										<Text>Buscando dados de recalls, aguarde...</Text>
									</Box>
								) : props.recalls?.data?.length ? (
									<>
										{props.recalls.data.map((recall, index) => (
											<div key={index}>
												<AtpvInput
													isTextArea
													minH='120px'
													children='RECALL'
													value={`${recall.formatted_register_date} - ${recall.name}`}
													id={`input__vehicle-recall-name-${index}`}
												/>
												<AtpvInput
													isTextArea
													minH='150px'
													children='DESCRIÇÃO RECALL'
													value={recall.description}
													id={`input__vehicle-recall-description-${index}`}
												/>
											</div>
										))}
									</>
								) : (
									<AtpvInput
										isTextArea
										minH='120px'
										children='RECALL'
										value={props.recalls?.message || 'Sem informações'}
										id='input__vehicle-recall-name'
									/>
								)}
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				<InquiriesPrint report={props.report} inquiries={props.inquiries} />
				<Stack mt='20px'>
					<Text textAlign={['center', 'center', 'start']} className='print-validation-certificate'>
						CERTIFICADO DE VALIDAÇÃO ID: {props.report?.transaction_number} - {searchDate}
					</Text>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default AtpvDocument;
