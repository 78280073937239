/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecallData } from './RecallData';
import {
    RecallDataFromJSON,
    RecallDataFromJSONTyped,
    RecallDataToJSON,
} from './RecallData';

/**
 * 
 * @export
 * @interface Recalls
 */
export interface Recalls {
    /**
     * 
     * @type {Array<RecallData>}
     * @memberof Recalls
     */
    data: Array<RecallData>;
    /**
     * 
     * @type {string}
     * @memberof Recalls
     */
    message: string;
}

/**
 * Check if a given object implements the Recalls interface.
 */
export function instanceOfRecalls(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function RecallsFromJSON(json: any): Recalls {
    return RecallsFromJSONTyped(json, false);
}

export function RecallsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Recalls {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(RecallDataFromJSON)),
        'message': json['message'],
    };
}

export function RecallsToJSON(value?: Recalls | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(RecallDataToJSON)),
        'message': value.message,
    };
}

