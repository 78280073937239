/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSummaryResponseDto
 */
export interface GetSummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_today: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_month: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_today_atpve: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_month_atpve: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_atpve: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_today_crv: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_month_crv: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_crv: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_today_value: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_month_value: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponseDto
     */
    total_value: string;
}

/**
 * Check if a given object implements the GetSummaryResponseDto interface.
 */
export function instanceOfGetSummaryResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total_today" in value;
    isInstance = isInstance && "total_month" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "total_today_atpve" in value;
    isInstance = isInstance && "total_month_atpve" in value;
    isInstance = isInstance && "total_atpve" in value;
    isInstance = isInstance && "total_today_crv" in value;
    isInstance = isInstance && "total_month_crv" in value;
    isInstance = isInstance && "total_crv" in value;
    isInstance = isInstance && "total_today_value" in value;
    isInstance = isInstance && "total_month_value" in value;
    isInstance = isInstance && "total_value" in value;

    return isInstance;
}

export function GetSummaryResponseDtoFromJSON(json: any): GetSummaryResponseDto {
    return GetSummaryResponseDtoFromJSONTyped(json, false);
}

export function GetSummaryResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSummaryResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total_today': json['total_today'],
        'total_month': json['total_month'],
        'total': json['total'],
        'total_today_atpve': json['total_today_atpve'],
        'total_month_atpve': json['total_month_atpve'],
        'total_atpve': json['total_atpve'],
        'total_today_crv': json['total_today_crv'],
        'total_month_crv': json['total_month_crv'],
        'total_crv': json['total_crv'],
        'total_today_value': json['total_today_value'],
        'total_month_value': json['total_month_value'],
        'total_value': json['total_value'],
    };
}

export function GetSummaryResponseDtoToJSON(value?: GetSummaryResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_today': value.total_today,
        'total_month': value.total_month,
        'total': value.total,
        'total_today_atpve': value.total_today_atpve,
        'total_month_atpve': value.total_month_atpve,
        'total_atpve': value.total_atpve,
        'total_today_crv': value.total_today_crv,
        'total_month_crv': value.total_month_crv,
        'total_crv': value.total_crv,
        'total_today_value': value.total_today_value,
        'total_month_value': value.total_month_value,
        'total_value': value.total_value,
    };
}

