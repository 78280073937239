/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayerDto } from './PayerDto';
import {
    PayerDtoFromJSON,
    PayerDtoFromJSONTyped,
    PayerDtoToJSON,
} from './PayerDto';

/**
 * 
 * @export
 * @interface GetConsultDebtLinkDto
 */
export interface GetConsultDebtLinkDto {
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    license_plate: string;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    report_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    owner_name: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetConsultDebtLinkDto
     */
    is_paid: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetConsultDebtLinkDto
     */
    consult_value: number;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    vehicle_state: string;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    history_url: string;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    free_history_url: string;
    /**
     * 
     * @type {PayerDto}
     * @memberof GetConsultDebtLinkDto
     */
    payer?: PayerDto;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    receipt?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConsultDebtLinkDto
     */
    created_at?: string;
}

/**
 * Check if a given object implements the GetConsultDebtLinkDto interface.
 */
export function instanceOfGetConsultDebtLinkDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "license_plate" in value;
    isInstance = isInstance && "owner_name" in value;
    isInstance = isInstance && "is_paid" in value;
    isInstance = isInstance && "consult_value" in value;
    isInstance = isInstance && "vehicle_state" in value;
    isInstance = isInstance && "history_url" in value;
    isInstance = isInstance && "free_history_url" in value;

    return isInstance;
}

export function GetConsultDebtLinkDtoFromJSON(json: any): GetConsultDebtLinkDto {
    return GetConsultDebtLinkDtoFromJSONTyped(json, false);
}

export function GetConsultDebtLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConsultDebtLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': json['token'],
        'license_plate': json['license_plate'],
        'report_id': !exists(json, 'report_id') ? undefined : json['report_id'],
        'owner_name': json['owner_name'],
        'is_paid': json['is_paid'],
        'consult_value': json['consult_value'],
        'vehicle_state': json['vehicle_state'],
        'history_url': json['history_url'],
        'free_history_url': json['free_history_url'],
        'payer': !exists(json, 'payer') ? undefined : PayerDtoFromJSON(json['payer']),
        'receipt': !exists(json, 'receipt') ? undefined : json['receipt'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
    };
}

export function GetConsultDebtLinkDtoToJSON(value?: GetConsultDebtLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': value.token,
        'license_plate': value.license_plate,
        'report_id': value.report_id,
        'owner_name': value.owner_name,
        'is_paid': value.is_paid,
        'consult_value': value.consult_value,
        'vehicle_state': value.vehicle_state,
        'history_url': value.history_url,
        'free_history_url': value.free_history_url,
        'payer': PayerDtoToJSON(value.payer),
        'receipt': value.receipt,
        'created_at': value.created_at,
    };
}

