/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterUserDto
 */
export interface RegisterUserDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    seller_cns: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    seller_document: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    seller_email: string;
}

/**
 * Check if a given object implements the RegisterUserDto interface.
 */
export function instanceOfRegisterUserDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "document" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "seller_cns" in value;
    isInstance = isInstance && "seller_document" in value;
    isInstance = isInstance && "seller_name" in value;
    isInstance = isInstance && "seller_email" in value;

    return isInstance;
}

export function RegisterUserDtoFromJSON(json: any): RegisterUserDto {
    return RegisterUserDtoFromJSONTyped(json, false);
}

export function RegisterUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'document': json['document'],
        'email': json['email'],
        'password': json['password'],
        'seller_cns': json['seller_cns'],
        'seller_document': json['seller_document'],
        'seller_name': json['seller_name'],
        'seller_email': json['seller_email'],
    };
}

export function RegisterUserDtoToJSON(value?: RegisterUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'document': value.document,
        'email': value.email,
        'password': value.password,
        'seller_cns': value.seller_cns,
        'seller_document': value.seller_document,
        'seller_name': value.seller_name,
        'seller_email': value.seller_email,
    };
}

