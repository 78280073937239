/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendBilletEmailDto
 */
export interface SendBilletEmailDto {
    /**
     * Formato: yyyy-mm-dd
     * @type {string}
     * @memberof SendBilletEmailDto
     */
    date?: string;
    /**
     * Lista de ids do cartórios
     * @type {string}
     * @memberof SendBilletEmailDto
     */
    seller_ids?: string;
    /**
     * Status de Pagamento
     * @type {string}
     * @memberof SendBilletEmailDto
     */
    payment_status?: SendBilletEmailDtoPaymentStatusEnum;
    /**
     * Status de Envio
     * @type {string}
     * @memberof SendBilletEmailDto
     */
    shipping_status?: SendBilletEmailDtoShippingStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendBilletEmailDto
     */
    ids?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SendBilletEmailDto
     */
    all_billets?: boolean;
}


/**
 * @export
 */
export const SendBilletEmailDtoPaymentStatusEnum = {
    Pending: 'pending',
    Success: 'success',
    Expired: 'expired'
} as const;
export type SendBilletEmailDtoPaymentStatusEnum = typeof SendBilletEmailDtoPaymentStatusEnum[keyof typeof SendBilletEmailDtoPaymentStatusEnum];

/**
 * @export
 */
export const SendBilletEmailDtoShippingStatusEnum = {
    Pending: 'pending',
    Received: 'received',
    Failed: 'failed'
} as const;
export type SendBilletEmailDtoShippingStatusEnum = typeof SendBilletEmailDtoShippingStatusEnum[keyof typeof SendBilletEmailDtoShippingStatusEnum];


/**
 * Check if a given object implements the SendBilletEmailDto interface.
 */
export function instanceOfSendBilletEmailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SendBilletEmailDtoFromJSON(json: any): SendBilletEmailDto {
    return SendBilletEmailDtoFromJSONTyped(json, false);
}

export function SendBilletEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendBilletEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'seller_ids': !exists(json, 'seller_ids') ? undefined : json['seller_ids'],
        'payment_status': !exists(json, 'payment_status') ? undefined : json['payment_status'],
        'shipping_status': !exists(json, 'shipping_status') ? undefined : json['shipping_status'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'all_billets': !exists(json, 'all_billets') ? undefined : json['all_billets'],
    };
}

export function SendBilletEmailDtoToJSON(value?: SendBilletEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'seller_ids': value.seller_ids,
        'payment_status': value.payment_status,
        'shipping_status': value.shipping_status,
        'ids': value.ids,
        'all_billets': value.all_billets,
    };
}

