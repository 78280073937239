/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetVehicleInquiriesDto } from './GetVehicleInquiriesDto';
import {
    GetVehicleInquiriesDtoFromJSON,
    GetVehicleInquiriesDtoFromJSONTyped,
    GetVehicleInquiriesDtoToJSON,
} from './GetVehicleInquiriesDto';
import type { ReportPayer } from './ReportPayer';
import {
    ReportPayerFromJSON,
    ReportPayerFromJSONTyped,
    ReportPayerToJSON,
} from './ReportPayer';
import type { ReportSeller } from './ReportSeller';
import {
    ReportSellerFromJSON,
    ReportSellerFromJSONTyped,
    ReportSellerToJSON,
} from './ReportSeller';

/**
 * 
 * @export
 * @interface GetReportDto
 */
export interface GetReportDto {
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    license_plate: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    renavam?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    chassis?: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    document_type: GetReportDtoDocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    cns: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    status: string;
    /**
     * 
     * @type {ReportSeller}
     * @memberof GetReportDto
     */
    seller?: ReportSeller;
    /**
     * 
     * @type {ReportPayer}
     * @memberof GetReportDto
     */
    payer?: ReportPayer;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    url?: string;
    /**
     * 
     * @type {GetVehicleInquiriesDto}
     * @memberof GetReportDto
     */
    inquiries?: GetVehicleInquiriesDto;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    transaction_number: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    user_name: string;
    /**
     * 
     * @type {Date}
     * @memberof GetReportDto
     */
    reprocessing_date?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetReportDto
     */
    consult_value?: number;
    /**
     * 
     * @type {string}
     * @memberof GetReportDto
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetReportDto
     */
    updated_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetReportDto
     */
    created_at: Date;
}


/**
 * @export
 */
export const GetReportDtoDocumentTypeEnum = {
    Crv: 'CRV',
    Atpve: 'ATPVe'
} as const;
export type GetReportDtoDocumentTypeEnum = typeof GetReportDtoDocumentTypeEnum[keyof typeof GetReportDtoDocumentTypeEnum];


/**
 * Check if a given object implements the GetReportDto interface.
 */
export function instanceOfGetReportDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "license_plate" in value;
    isInstance = isInstance && "document_type" in value;
    isInstance = isInstance && "cns" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "transaction_number" in value;
    isInstance = isInstance && "user_name" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function GetReportDtoFromJSON(json: any): GetReportDto {
    return GetReportDtoFromJSONTyped(json, false);
}

export function GetReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'license_plate': json['license_plate'],
        'renavam': !exists(json, 'renavam') ? undefined : json['renavam'],
        'chassis': !exists(json, 'chassis') ? undefined : json['chassis'],
        'document_type': json['document_type'],
        'cns': json['cns'],
        'status': json['status'],
        'seller': !exists(json, 'seller') ? undefined : ReportSellerFromJSON(json['seller']),
        'payer': !exists(json, 'payer') ? undefined : ReportPayerFromJSON(json['payer']),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'inquiries': !exists(json, 'inquiries') ? undefined : GetVehicleInquiriesDtoFromJSON(json['inquiries']),
        'transaction_number': json['transaction_number'],
        'user_name': json['user_name'],
        'reprocessing_date': !exists(json, 'reprocessing_date') ? undefined : (new Date(json['reprocessing_date'])),
        'consult_value': !exists(json, 'consult_value') ? undefined : json['consult_value'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'created_at': (new Date(json['created_at'])),
    };
}

export function GetReportDtoToJSON(value?: GetReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'license_plate': value.license_plate,
        'renavam': value.renavam,
        'chassis': value.chassis,
        'document_type': value.document_type,
        'cns': value.cns,
        'status': value.status,
        'seller': ReportSellerToJSON(value.seller),
        'payer': ReportPayerToJSON(value.payer),
        'url': value.url,
        'inquiries': GetVehicleInquiriesDtoToJSON(value.inquiries),
        'transaction_number': value.transaction_number,
        'user_name': value.user_name,
        'reprocessing_date': value.reprocessing_date === undefined ? undefined : (value.reprocessing_date.toISOString()),
        'consult_value': value.consult_value,
        'email': value.email,
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
    };
}

