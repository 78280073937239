/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetConsultDebtLinkDto,
} from '../models/index';
import {
    GetConsultDebtLinkDtoFromJSON,
    GetConsultDebtLinkDtoToJSON,
} from '../models/index';

export interface GetConsultDebtLinkRequest {
    token: string;
}

/**
 * 
 */
export class ConsultDebtsLinksApi extends runtime.BaseAPI {

    /**
     * Buscar informações do link de consulta de débitos
     */
    async getConsultDebtLinkRaw(requestParameters: GetConsultDebtLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetConsultDebtLinkDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getConsultDebtLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/consult-debts/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConsultDebtLinkDtoFromJSON(jsonValue));
    }

    /**
     * Buscar informações do link de consulta de débitos
     */
    async getConsultDebtLink(requestParameters: GetConsultDebtLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetConsultDebtLinkDto> {
        const response = await this.getConsultDebtLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
