/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserLoginSellerDto
 */
export interface GetUserLoginSellerDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    cellphone: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    owner_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    cpf: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    cnpj: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    cns: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    address_street: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    address_number: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    address_complement: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    address_district: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    address_city: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    address_state: string;
}

/**
 * Check if a given object implements the GetUserLoginSellerDto interface.
 */
export function instanceOfGetUserLoginSellerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "cellphone" in value;
    isInstance = isInstance && "owner_name" in value;
    isInstance = isInstance && "cpf" in value;
    isInstance = isInstance && "cnpj" in value;
    isInstance = isInstance && "cns" in value;
    isInstance = isInstance && "address_street" in value;
    isInstance = isInstance && "address_number" in value;
    isInstance = isInstance && "address_complement" in value;
    isInstance = isInstance && "address_district" in value;
    isInstance = isInstance && "address_city" in value;
    isInstance = isInstance && "address_state" in value;

    return isInstance;
}

export function GetUserLoginSellerDtoFromJSON(json: any): GetUserLoginSellerDto {
    return GetUserLoginSellerDtoFromJSONTyped(json, false);
}

export function GetUserLoginSellerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserLoginSellerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client_id': json['client_id'],
        'name': json['name'],
        'email': json['email'],
        'phone': json['phone'],
        'cellphone': json['cellphone'],
        'owner_name': json['owner_name'],
        'cpf': json['cpf'],
        'cnpj': json['cnpj'],
        'cns': json['cns'],
        'address_street': json['address_street'],
        'address_number': json['address_number'],
        'address_complement': json['address_complement'],
        'address_district': json['address_district'],
        'address_city': json['address_city'],
        'address_state': json['address_state'],
    };
}

export function GetUserLoginSellerDtoToJSON(value?: GetUserLoginSellerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client_id': value.client_id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'cellphone': value.cellphone,
        'owner_name': value.owner_name,
        'cpf': value.cpf,
        'cnpj': value.cnpj,
        'cns': value.cns,
        'address_street': value.address_street,
        'address_number': value.address_number,
        'address_complement': value.address_complement,
        'address_district': value.address_district,
        'address_city': value.address_city,
        'address_state': value.address_state,
    };
}

