/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmMailDto
 */
export interface ConfirmMailDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmMailDto
     */
    email_key: string;
}

/**
 * Check if a given object implements the ConfirmMailDto interface.
 */
export function instanceOfConfirmMailDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email_key" in value;

    return isInstance;
}

export function ConfirmMailDtoFromJSON(json: any): ConfirmMailDto {
    return ConfirmMailDtoFromJSONTyped(json, false);
}

export function ConfirmMailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmMailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_key': json['email_key'],
    };
}

export function ConfirmMailDtoToJSON(value?: ConfirmMailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_key': value.email_key,
    };
}

