import {
	Button as ChakraButton,
	Flex,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Stack,
	Table,
	TableCaption,
	TableContainer,
	TableContainerProps,
	Tbody,
	Td,
	Tr,
} from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { GetPaginatedUnpaidBilletsDto, GetUnpaidBilletDto } from '../../../clients';
import Text from '../../../components/Text';
import { defaultColors } from '../../../config/variables';
import { centsToRealWithComma } from '../../../services/replaceDotWithComma.service';
import { DataValues } from '../interfaces/DataValues';

type TableProps = TableContainerProps & {
	unpaidBillets: GetPaginatedUnpaidBilletsDto | undefined;
	body: GetUnpaidBilletDto[] | undefined;
	dataValues: DataValues;
	setDataValues: Dispatch<SetStateAction<DataValues>>;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setLimit: Dispatch<SetStateAction<number>>;
	fetchData: (dataValues: DataValues) => Promise<void>;
	currentPage: number;
	limit: number;
};

const UnpaidBilletsTable = (props: TableProps) => {
	return (
		<>
			<TableContainer w='100%'>
				<Table w='100%' variant='simple' size='md' display={['none', 'none', 'table']}>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody key={body.seller_id} _hover={{ background: '#CCCCCC' }}>
							<Tr>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										ID
									</Text>
									<div>{body?.seller_id}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										VALOR
									</Text>
									<Flex flexDirection='row' alignItems='center'>
										R$ {centsToRealWithComma(body.amount || 0)}
									</Flex>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CARTÓRIO
									</Text>
									<div>{body?.seller_name || 'Não Informado'}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS
									</Text>
									<div>
										{body?.seller_cns
											? JSON.stringify(body?.seller_cns)
													.replace(/"/g, '')
													.replace(/(\d{2})(\d)/, '$1.$2')
													.replace(/(\d{3})(\d)/, '$1-$2')
											: 'Não Informado'}
									</div>
								</Td>
							</Tr>
						</Tbody>
					))}
				</Table>
				<Table variant='simple' size='md' display={['flex', 'flex', 'none']} flexDirection='column' w='100%'>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody
							w='100%'
							display='flex'
							alignItems='center'
							flexDirection='column'
							p='10px'
							bg={defaultColors.white}
							boxShadow='0px 2px 4px rgba(0, 0, 0, 0.1)'
							borderRadius='8px'
							border='2px solid rgba(0, 0, 0, 0.2)'
							mb='10px'
							_hover={{ background: defaultColors.white }}
						>
							<Tr w='100%' display='flex' flexDirection='column' alignItems='center' gap='12px'>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										ID:
									</Text>
									<div>{body?.seller_id}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										VALOR:
									</Text>
									<div>R$ {centsToRealWithComma(body.amount || 0)}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CARTÓRIO:
									</Text>
									<div>{body?.seller_name || 'Não Informado'}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS:
									</Text>
									<div>
										{body?.seller_cns
											? JSON.stringify(body?.seller_cns)
													.replace(/"/g, '')
													.replace(/(\d{2})(\d)/, '$1.$2')
													.replace(/(\d{3})(\d)/, '$1-$2')
											: 'Não Informado'}
									</div>
								</Flex>
							</Tr>
						</Tbody>
					))}
				</Table>
			</TableContainer>
			{props?.body && props.body.length > 0 && (
				<Stack justifyContent='space-around' flexDirection='row'>
					<Stack flexDirection='row'>
						<ChakraButton
							variant='link'
							id='button__double-arrow-left'
							color='black'
							onClick={() => {
								if (props.currentPage > 1) {
									props.setCurrentPage(1);
									const dataValues = { ...props.dataValues, currentPage: 1 };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardDoubleArrowLeft size='20px' />
						</ChakraButton>
						<ChakraButton
							variant='link'
							id='button__arrow-left'
							color='black'
							onClick={() => {
								if (props.currentPage > 1) {
									const previousPage = props.currentPage - 1;
									props.setCurrentPage(previousPage);
									const dataValues = { ...props.dataValues, currentPage: previousPage };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardArrowLeft size='20px' />
						</ChakraButton>
					</Stack>
					<Stack>
						<Text>
							<Stack flexDirection='row' alignItems='center' justifyContent='space-around' spacing='40px'>
								<Stack>
									<Text>
										Página {props.unpaidBillets?.current_page} de {props.unpaidBillets?.total_pages}
									</Text>
								</Stack>
								<Stack flexDirection='row' alignItems='center' w='40%'>
									<Text>Ir para página: </Text>
									<NumberInput
										id='input__page-number'
										min={1}
										max={props.unpaidBillets?.total_pages}
										w='50%'
										minW='70px'
										defaultValue={props.currentPage}
										value={props.currentPage}
										onChange={(e) => {
											const newPage = Number(e);
											props.setCurrentPage(newPage);
											if (
												props.unpaidBillets &&
												props.unpaidBillets.total_pages &&
												newPage >= 1 &&
												newPage <= props.unpaidBillets.total_pages
											) {
												const dataValues = { ...props.dataValues, currentPage: newPage };
												props.setDataValues(dataValues);
												props.fetchData(dataValues);
											}
										}}
									>
										<NumberInputField
											min={1}
											max={props.unpaidBillets?.total_pages}
											border='2px solid black'
											borderRadius='15px'
											_disabled={{
												color: 'black',
											}}
										/>
										<NumberInputStepper w='30%'>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Stack>
								<Stack>
									<Select
										id='input_select'
										defaultValue={10}
										w='60%'
										minW='120px'
										border='2px solid black'
										borderRadius='15px'
										value={props.limit}
										onChange={(e) => {
											const selectedValue = Number(e.target.value);
											props.setLimit(selectedValue);
											const dataValues = { ...props.dataValues, limit: selectedValue, currentPage: 1 };
											props.setDataValues(dataValues);
											props.setCurrentPage(1);
											props.fetchData(dataValues);
										}}
									>
										<option value={10}>Ver 10</option>
										<option value={20}>Ver 20</option>
										<option value={30}>Ver 30</option>
										<option value={40}>Ver 40</option>
										<option value={50}>Ver 50</option>
										<option value={100}>Ver 100</option>
									</Select>
								</Stack>
							</Stack>
						</Text>
					</Stack>
					<Stack flexDirection='row'>
						<ChakraButton
							variant='link'
							color='black'
							id='button__arrow-right'
							onClick={() => {
								const totalPages = props?.unpaidBillets?.total_pages || 1;
								if (props.currentPage < totalPages) {
									const nextPage = props.currentPage + 1;
									props.setCurrentPage(nextPage);
									const dataValues = { ...props.dataValues, currentPage: nextPage };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardArrowRight size='20px' />
						</ChakraButton>
						<ChakraButton
							variant='link'
							color='black'
							id='button__double-arrow-right'
							onClick={() => {
								const totalPages = props?.unpaidBillets?.total_pages || 1;
								if (props.currentPage < totalPages) {
									props.setCurrentPage(totalPages);
									const dataValues = { ...props.dataValues, currentPage: totalPages };
									props.setDataValues(dataValues);
									props.fetchData(dataValues);
								}
							}}
						>
							<MdKeyboardDoubleArrowRight size='20px' />
						</ChakraButton>
					</Stack>
				</Stack>
			)}
		</>
	);
};

export default UnpaidBilletsTable;
