/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddPayerDto
 */
export interface AddPayerDto {
    /**
     * Nome do pagador, máximo 150 caracteres
     * @type {string}
     * @memberof AddPayerDto
     */
    payer_name: string;
    /**
     * 
     * @type {string}
     * @memberof AddPayerDto
     */
    payer_document: string;
}

/**
 * Check if a given object implements the AddPayerDto interface.
 */
export function instanceOfAddPayerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payer_name" in value;
    isInstance = isInstance && "payer_document" in value;

    return isInstance;
}

export function AddPayerDtoFromJSON(json: any): AddPayerDto {
    return AddPayerDtoFromJSONTyped(json, false);
}

export function AddPayerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPayerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payer_name': json['payer_name'],
        'payer_document': json['payer_document'],
    };
}

export function AddPayerDtoToJSON(value?: AddPayerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payer_name': value.payer_name,
        'payer_document': value.payer_document,
    };
}

