/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUngeneratedBilletDto
 */
export interface GetUngeneratedBilletDto {
    /**
     * 
     * @type {string}
     * @memberof GetUngeneratedBilletDto
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetUngeneratedBilletDto
     */
    seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetUngeneratedBilletDto
     */
    seller_email: string;
    /**
     * 
     * @type {string}
     * @memberof GetUngeneratedBilletDto
     */
    seller_cns: string;
    /**
     * 
     * @type {number}
     * @memberof GetUngeneratedBilletDto
     */
    total_consult_value: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetUngeneratedBilletDto
     */
    not_found: boolean;
}

/**
 * Check if a given object implements the GetUngeneratedBilletDto interface.
 */
export function instanceOfGetUngeneratedBilletDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "seller_id" in value;
    isInstance = isInstance && "seller_name" in value;
    isInstance = isInstance && "seller_email" in value;
    isInstance = isInstance && "seller_cns" in value;
    isInstance = isInstance && "total_consult_value" in value;
    isInstance = isInstance && "not_found" in value;

    return isInstance;
}

export function GetUngeneratedBilletDtoFromJSON(json: any): GetUngeneratedBilletDto {
    return GetUngeneratedBilletDtoFromJSONTyped(json, false);
}

export function GetUngeneratedBilletDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUngeneratedBilletDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seller_id': json['seller_id'],
        'seller_name': json['seller_name'],
        'seller_email': json['seller_email'],
        'seller_cns': json['seller_cns'],
        'total_consult_value': json['total_consult_value'],
        'not_found': json['not_found'],
    };
}

export function GetUngeneratedBilletDtoToJSON(value?: GetUngeneratedBilletDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seller_id': value.seller_id,
        'seller_name': value.seller_name,
        'seller_email': value.seller_email,
        'seller_cns': value.seller_cns,
        'total_consult_value': value.total_consult_value,
        'not_found': value.not_found,
    };
}

