import React, { useEffect } from 'react';

import {
	Select as ChakraSelect,
	Checkbox,
	Flex,
	InputGroup,
	InputLeftAddon,
	Spacer,
	Spinner,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useDisclosure,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import FileDownload from 'js-file-download';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';

import {
	BilletsApi,
	GetBilletsPaymentStatusEnum,
	GetBilletsShippingStatusEnum,
	GetPaginatedBilletsDto,
	GetPaginatedUnpaidBilletsDto,
	ReportsApi,
	SellersApi,
	SendBilletEmailDto,
} from '../../clients';

import { getApiAuthConfig } from '../../services/api.service';

import { DataValues } from './interfaces/DataValues';

import { useAuth } from '../../contexts/AuthProvider';

import { endOfMonth, startOfDay, startOfMonth, subMonths } from 'date-fns';
import { GetPaginatedUngeneratedBilletsDto } from '../../clients/models/GetPaginatedUngeneratedBilletsDto';
import Button from '../../components/Button';
import FormControl from '../../components/Form/FormControl';
import MonthYearPicker from '../../components/MonthYearPicker';
import { defaultColors } from '../../config/variables';
import { SellersOptions } from '../Reports/interfaces/SellersOptions';
import { customStyle } from '../Reports/styles/SelectStyle';
import FinancialTable from './Table/FinancialTable';
import UngeneratedBilletsTable from './Table/UngeneratedBilletsTable';
import ConfirmModal from './components/ConfirmModal';
import { TabIds } from './enums/TabEnum';
import UnpaidBilletsTable from './Table/UnpaidBilletsTable';

const Financial: React.FC = () => {
	const { addToast } = useToasts();
	const { isBackoffice } = useAuth();
	const apiConfig = getApiAuthConfig();
	const billetsApi = new BilletsApi(apiConfig);
	const reportsApi = new ReportsApi(apiConfig);
	const sellersApi = new SellersApi(apiConfig);

	const initialFormValues = {
		selectedDate: {
			start_date: startOfDay(startOfMonth(new Date())),
			end_date: startOfDay(endOfMonth(new Date())),
		},
		currentPage: 1,
		limit: 10,
		sellerIds: [],
		paymentStatus: undefined,
		shippingStatus: undefined,
	};

	const [billets, setBillets] = React.useState<GetPaginatedBilletsDto | undefined>(undefined);
	const [ungeneratedBillets, setUngeneratedBillets] = React.useState<GetPaginatedUngeneratedBilletsDto | undefined>(undefined);
	const [unpaidBillets, setUnpaidBillets] = React.useState<GetPaginatedUnpaidBilletsDto | undefined>(undefined);
	const [sellersData, setSellersData] = React.useState<SellersOptions[] | undefined>(undefined);
	const [sellerIds, setSellerIds] = React.useState<SellersOptions[] | undefined>(initialFormValues.sellerIds);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [generateBilletsLoading, setGenerateBilletsLoading] = React.useState<boolean>(false);
	const [sendBilletsLoading, setSendBilletsLoading] = React.useState<boolean>(false);
	const [financialReportsLoading, setFinancialReportsLoading] = React.useState<boolean>(false);
	const [unpaidBilletsLoading, setUnpaidBilletsLoading] = React.useState<boolean>(false);
	const [paymentStatus, setPaymentStatus] = React.useState<GetBilletsPaymentStatusEnum | undefined>(initialFormValues.paymentStatus);
	const [shippingStatus, setShippingStatus] = React.useState<GetBilletsShippingStatusEnum | undefined>(initialFormValues.shippingStatus);
	const [currentPage, setCurrentPage] = React.useState<number>(initialFormValues.currentPage);
	const [limit, setLimit] = React.useState<number>(initialFormValues.limit);
	const [selectAll, setSelectAll] = React.useState(false);
	const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
	const [selectedTabId, setSelectedTabId] = React.useState<TabIds | null>(TabIds.Billets);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [selectedDate, setSelectedDate] = React.useState<any>(initialFormValues.selectedDate);

	const [dataValues, setDataValues] = React.useState<DataValues>({
		startDate: selectedDate.start_date.toString(),
		endDate: selectedDate.end_date.toString(),
		paymentStatus,
		sellerIds: [],
		limit,
		currentPage,
		shippingStatus,
	});

	const initialValues = {
		selectedDate,
		sellerIds,
		shippingStatus,
		paymentStatus,
	};

	const shippingTranslations = {
		pending: 'Pendente',
		received: 'Enviado',
		failed: 'Falho',
	};

	const paymentTranslations = {
		pending: 'Pendente',
		success: 'Sucesso',
		expired: 'Expirado',
	};

	const handleTabChange = (index: number) => {
		const selectedTab = Object.values(TabIds)[index] as TabIds;
		setSelectedTabId(selectedTab);
	};

	async function generateMonthBillets() {
		setGenerateBilletsLoading(true);
		try {
			await billetsApi.generateBillets();
			addToast(`Geração de boletos iniciada com sucesso`, {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast(`Ocorreu um erro ao tentar gerar os boletos.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setGenerateBilletsLoading(false);
		}
	}

	async function sendBilletsByEmail() {
		setSendBilletsLoading(true);
		try {
			const sendBilletEmailDto: SendBilletEmailDto = {
				all_billets: selectAll,
				date: dataValues?.startDate,
				ids: selectedRows,
				payment_status: dataValues?.paymentStatus,
				shipping_status: dataValues?.shippingStatus,
				seller_ids: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
			};
			await billetsApi.sendBilletEmail({ sendBilletEmailDto });
			addToast(`Envio de boletos por email iniciado com sucesso.`, {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast(`Ocorreu um erro ao tentar enviar os boletos por email.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSendBilletsLoading(false);
		}
	}

	async function fetchData(dataValues: DataValues) {
		setLoading(true);
		if (selectedTabId === TabIds.Billets) {
			const billetsReponse = await billetsApi.getBillets({
				startDate: dataValues?.startDate,
				endDate: dataValues?.endDate,
				limit: dataValues.limit,
				currentPage: dataValues.currentPage,
				paymentStatus: dataValues.paymentStatus,
				shippingStatus: dataValues.shippingStatus,
				sellerIds: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
			});
			setBillets(billetsReponse);
			setSelectAll(false);
			setSelectedRows([]);
		}
		if (selectedTabId === TabIds.Ungenerated) {
			const parsedStartDate = subMonths(new Date(dataValues?.startDate), 1).toString();
			const parsedEndDate = subMonths(new Date(dataValues?.endDate), 1).toString();

			const ungeneratedBilletsReponse = await billetsApi.getUngeneratedBillets({
				startDate: parsedStartDate,
				endDate: parsedEndDate,
				limit: dataValues.limit,
				currentPage: dataValues.currentPage,
				sellerIds: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
			});
			setUngeneratedBillets(ungeneratedBilletsReponse);
		}
		if (selectedTabId === TabIds.Unpaid) {
			const parsedStartDate = dataValues?.startDate;
			const parsedEndDate = dataValues?.endDate;
			const unpaidBilletsResponse = await billetsApi.getUnpaidBillets({
				startDate: parsedStartDate,
				endDate: parsedEndDate,
				limit: dataValues.limit,
				currentPage: dataValues.currentPage,
				sellerIds: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
			});
			setUnpaidBillets(unpaidBilletsResponse);
		}
		setLoading(false);
	}

	async function exportFinancialReports() {
		setFinancialReportsLoading(true);
		try {
			const exportFinancialReports = await reportsApi.getExportedFinancialReport({
				startDate: subMonths(new Date(dataValues?.startDate), 1).toString(),
				endDate: subMonths(new Date(dataValues?.endDate), 1).toString(),
				sellerIds: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
			});
			FileDownload(exportFinancialReports, 'Relatório Financeiro.xlsx');
		} catch (error) {
			addToast(`Ocorreu um erro ao tentar exportar o relatório financeiro.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setFinancialReportsLoading(false);
		}
	}

	async function exportUnpaidBillets() {
		setUnpaidBilletsLoading(true);
		try {
			const exportUnpaidBillets = await billetsApi.getExportedUnpaidBillets();
			FileDownload(exportUnpaidBillets, 'Boletos não pagos.xlsx');
		} catch (error) {
			addToast(`Ocorreu um erro ao tentar exportar o relatório de boletos não pagos.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setUnpaidBilletsLoading(false);
		}
	}

	async function fetchSellersData() {
		const sellersResponse = await sellersApi.getSellers();
		const parsedSellers = sellersResponse.map((seller) => ({ value: seller.cns, label: seller.name, id: seller.id }));
		setSellersData(parsedSellers);
	}

	const handleFormSubmit = async () => {
		const startDate = selectedDate.start_date;
		const endDate = selectedDate.end_date;
		const currentPage = 1;
		const dataValues = { startDate, endDate, sellerIds, limit, currentPage, paymentStatus, shippingStatus };
		setDataValues(dataValues);
		setCurrentPage(1);
		fetchData(dataValues);
	};

	const toggleRowSelection = (id: string, paymentStatus: GetBilletsPaymentStatusEnum) => {
		let updatedSelectedRows: string[] = [];

		if (selectedRows.includes(id)) {
			updatedSelectedRows = selectedRows.filter((row) => row !== id);
			setSelectAll(false);
		} else if (paymentStatus === GetBilletsPaymentStatusEnum.Pending) {
			updatedSelectedRows = [...selectedRows, id];
		}

		setSelectedRows(updatedSelectedRows);
	};

	const toggleSelectAll = () => {
		if (selectAll) {
			setSelectedRows([]);
		} else {
			const pendingIds =
				billets?.results.filter((result) => result.payment_status === GetBilletsPaymentStatusEnum.Pending).map((result) => result.id) || [];
			setSelectedRows(pendingIds);
		}
		setSelectAll(!selectAll);
	};

	useEffect(() => {
		if (isBackoffice) {
			fetchSellersData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setCurrentPage(initialFormValues.currentPage);
		setLimit(initialFormValues.limit);
		setSellerIds(initialFormValues.sellerIds);
		setPaymentStatus(initialFormValues.paymentStatus);
		setShippingStatus(initialFormValues.shippingStatus);
		setDataValues({
			startDate: selectedDate.start_date.toString(),
			endDate: selectedDate.end_date.toString(),
			currentPage: initialFormValues.currentPage,
			limit: initialFormValues.limit,
			sellerIds: initialFormValues.sellerIds,
			paymentStatus: initialFormValues.paymentStatus,
			shippingStatus: initialFormValues.shippingStatus,
		});
		fetchData({
			...initialFormValues,
			startDate: selectedDate.start_date.toString(),
			endDate: selectedDate.end_date.toString(),
		});
		// eslint-disable-next-line
	}, [selectedTabId]);

	return (
		<Flex flexDirection='column' w='100%' px={4} pt='20px' minH='88%' overflowX='auto'>
			{isBackoffice && selectedTabId === TabIds.Billets && (
				<Flex>
					<ConfirmModal isOpen={isOpen} onClose={onClose} handleModalSubmit={generateMonthBillets} />
					<Button size='md' onClick={onOpen} isLoading={generateBilletsLoading} borderRadius='10px'>
						Gerar títulos do mês
					</Button>
				</Flex>
			)}
			<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Stack
								direction={['column', 'column', 'row']}
								alignItems={['center', 'center', 'flex-start']}
								spacing='20px'
								mb='20px'
								mt='40px'
								w='100%'
								justify='flex-start'
								wrap='wrap'
							>
								<Flex>
									<FormControl id='date' mb={4}>
										<MonthYearPicker setDates={setSelectedDate} />
									</FormControl>
								</Flex>
								{isBackoffice && (
									<Flex w='250px'>
										<FormControl>
											<Select
												id='input__cns-name'
												name='cns'
												placeholder='Buscar'
												isMulti
												styles={customStyle}
												options={sellersData}
												onChange={(e) => setSellerIds(e as SellersOptions[])}
											/>
										</FormControl>
									</Flex>
								)}
								{selectedTabId === TabIds.Billets && (
									<Stack spacing={4} w='250px'>
										<InputGroup size='md' border='2px solid black' borderRadius='15px'>
											<InputLeftAddon bgColor='lightgray' border='none' borderRadius='15px 0px 0px 15px'>
												Pagamento
											</InputLeftAddon>
											<ChakraSelect
												name='paymentStatus'
												id='input__payment-status'
												borderRadius='0px 15px 15px 0px'
												onChange={(e) => {
													if (e.target.value === '') {
														setPaymentStatus(undefined);
													} else {
														setPaymentStatus(e.target.value as GetBilletsPaymentStatusEnum);
													}
												}}
												border='none'
												value={paymentStatus}
											>
												<option value=''>Todos</option>
												{Object.entries(GetBilletsPaymentStatusEnum).map(([key, value]) => (
													<option key={key} value={value}>
														{paymentTranslations[value]}
													</option>
												))}
											</ChakraSelect>
										</InputGroup>
									</Stack>
								)}
								{isBackoffice && selectedTabId === TabIds.Billets && (
									<Stack spacing={4} w='250px'>
										<InputGroup size='md' border='2px solid black' borderRadius='15px'>
											<InputLeftAddon bgColor='lightgray' border='none' borderRadius='15px 0px 0px 15px'>
												Envio
											</InputLeftAddon>
											<ChakraSelect
												name='shippingStatus'
												id='input__shipping-status'
												borderRadius='0px 15px 15px 0px'
												onChange={(e) => {
													if (e.target.value === '') {
														setShippingStatus(undefined);
													} else {
														setShippingStatus(e.target.value as GetBilletsShippingStatusEnum);
													}
												}}
												border='none'
												value={shippingStatus}
											>
												<option value=''>Todos</option>
												{Object.entries(GetBilletsShippingStatusEnum).map(([key, value]) => (
													<option key={key} value={value}>
														{shippingTranslations[value]}
													</option>
												))}
											</ChakraSelect>
										</InputGroup>
									</Stack>
								)}
								<Flex maxW='200px' flex='0 1 auto'>
									<FormControl>
										<Button
											id='button__search'
											type='submit'
											size='md'
											isLoading={loading}
											bgColor={defaultColors.primaryColor}
											borderRadius='10px'
											w='100%'
										>
											Buscar
										</Button>
									</FormControl>
								</Flex>
							</Stack>
						</form>
					);
				}}
			</Formik>
			<Tabs onChange={handleTabChange}>
				<TabList>
					<Tab id={TabIds.Billets}>Boletos</Tab>
					{isBackoffice && (
						<>
							<Tab id={TabIds.Ungenerated}>Não Gerados</Tab>
							<Tab id={TabIds.Unpaid}>Não Pagos</Tab>
						</>
					)}
				</TabList>

				<TabPanels>
					<TabPanel>
						<Flex flexDirection='column' w='100%' alignItems={['center', 'center', 'start']}>
							<Stack w='100%' mb={4} alignItems='center' mt='40px'>
								{loading ? (
									<Spinner size='lg' />
								) : (
									<>
										{billets?.results.length ? (
											<Stack w='100%' alignItems='center' flexDirection={['column', 'column', 'row']} justifyContent='space-between'>
												{isBackoffice && (
													<Checkbox isChecked={selectAll} onChange={toggleSelectAll} ml='22px' border='black'>
														Selecionar Todos
													</Checkbox>
												)}
												<Spacer />
												<Flex gap='10px' flexDirection={['column', 'column', 'row']}>
													<Button
														id='button__export-financial'
														h='40px'
														bgColor={defaultColors.primaryColor}
														onClick={exportFinancialReports}
														isLoading={financialReportsLoading}
														borderRadius='10px'
													>
														Exportar Relatório Financeiro
													</Button>
													{isBackoffice && (
														<Button
															id='button__send-billets'
															h='40px'
															isLoading={sendBilletsLoading}
															isDisabled={!selectedRows.length && !selectAll}
															onClick={sendBilletsByEmail}
															bgColor={defaultColors.primaryColor}
															borderRadius='10px'
														>
															Enviar Boletos Selecionados
														</Button>
													)}
												</Flex>
											</Stack>
										) : null}

										<FinancialTable
											limit={limit}
											currentPage={currentPage}
											fetchData={fetchData}
											setCurrentPage={setCurrentPage}
											setLimit={setLimit}
											setDataValues={setDataValues}
											dataValues={dataValues}
											billets={billets}
											body={billets?.results}
											selectedRows={selectedRows}
											toggleRowSelection={toggleRowSelection}
										/>
									</>
								)}
							</Stack>
						</Flex>
					</TabPanel>
					{isBackoffice && (
						<TabPanel>
							<Stack w='100%' mb={4} alignItems='center' mt='40px'>
								{loading ? (
									<Spinner size='lg' />
								) : (
									<UngeneratedBilletsTable
										limit={limit}
										currentPage={currentPage}
										fetchData={fetchData}
										setCurrentPage={setCurrentPage}
										setLimit={setLimit}
										setDataValues={setDataValues}
										dataValues={dataValues}
										ungeneratedBillets={ungeneratedBillets}
										body={ungeneratedBillets?.results}
									/>
								)}
							</Stack>
						</TabPanel>
					)}
					{isBackoffice && (
						<TabPanel>
							<Stack w='100%' mb={4} alignItems='center' mt='40px'>
								{loading ? (
									<Spinner size='lg' />
								) : (
									<>
										<Stack w='100%' alignItems='center' flexDirection={['column', 'column', 'row']} justifyContent='end'>
											<Button
												id='button__export-financial'
												h='40px'
												bgColor={defaultColors.primaryColor}
												onClick={exportUnpaidBillets}
												isLoading={unpaidBilletsLoading}
												borderRadius='10px'
											>
												Exportar Boletos Não Pagos
											</Button>
										</Stack>
										<UnpaidBilletsTable
											limit={limit}
											currentPage={currentPage}
											fetchData={fetchData}
											setCurrentPage={setCurrentPage}
											setLimit={setLimit}
											setDataValues={setDataValues}
											dataValues={dataValues}
											unpaidBillets={unpaidBillets}
											body={unpaidBillets?.results}
										/>
									</>
								)}
							</Stack>
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
		</Flex>
	);
};

export default Financial;
